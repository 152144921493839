<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="tempDescList" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Descriptions</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Template Description" icon="pi pi-plus" class="p-mr-2"
                                @click="addTempDescDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="name" header=" Template Description Name" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mbs2 ? data.mbs2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column field="uniqueId" header=" Unique Sequence Id" headerStyle="width: 30%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.mbs3 ? data.mbs3 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editTemplateDescDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add Template Descriptions dialog start here -->
    <Dialog v-model:visible="addTemplateDescStatus" :style="{ width: '800px' }" header="Add Template Description"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-pt-2 p-px-3">
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <label for="TemplateDescriptionid">
                                Template Description name
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="TemplateDescriptionid" class="p-text-capitalize"
                                v-model.trim="add.TemplateDescription" required="true" autofocus
                                :class="{ 'p-invalid': submitted && !add.TemplateDescription }" />

                            <small class="p-invalid p-error" v-if="v$.add.TemplateDescription.$error">{{
                                v$.add.TemplateDescription.$errors[0].$message
                            }}</small>
                        </div>

                        <div class="p-field p-col-12 p-md-6">
                            <label for="templateuniqueid">
                                Template Unique Id
                                <span class="p-invalid p-error">*</span>
                            </label>
                            <InputText id="templateuniqueid" class="p-text-capitalize" v-model.trim="add.templateuniqueid"
                                required="true" @input="isAlreadyPresentTemplateDescriptionId($event)" maxlength="3"
                                autofocus :class="{ 'p-invalid': submitted && !add.templateuniqueid }" />
                            <small class="p-invalid p-error" v-if="v$.add.templateuniqueid.$error">{{
                                v$.add.templateuniqueid.$errors[0].$message
                            }}</small>
                            <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg
                            }}</small>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addTemplateDescriptions" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add Template Descriptions dialog end here -->
    <!-- edit Template Descriptions dialog start here -->
    <Dialog v-model:visible="editTemplateDescStatus" :style="{ width: '800px' }" header="Edit Template Description" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="TemplateDescriptionid">
                        Template Description
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="TemplateDescriptionid" class="p-text-capitalize" v-model.trim="edit.TemplateDescription"
                        required="true" autofocus :class="{ 'p-invalid': submitted && !edit.TemplateDescription }" />
                    <small class="p-invalid p-error" v-if="v$.edit.TemplateDescription.$error">{{
                        v$.edit.TemplateDescription.$errors[0].$message
                    }}</small>
                </div>

                <div class="p-field p-col-12 p-md-6">
                    <label for="templateuniqueid">
                        Template Unique Id
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="templateuniqueid" class="p-text-capitalize" v-model.trim="edit.templateuniqueid"
                        required="true" maxlength="3" @input="isAlreadyPresentTemplateDescriptionId($event)" autofocus
                        :class="{ 'p-invalid': submitted && !edit.templateuniqueid }" />
                    <small class="p-invalid p-error" v-if="v$.edit.templateuniqueid.$error">{{
                        v$.edit.templateuniqueid.$errors[0].$message
                    }}</small>
                    <small class="p-invalid p-error" v-if="foundUniqueIdErrorMsg">{{ foundUniqueIdErrorMsg }}</small>

                </div>

            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateTemplateDescriptions" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit Template Descriptions dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers, numeric, maxLength } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            tempDescList: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addTemplateDescStatus: false,
            editTemplateDescStatus: false,
            showLoader: false,
            submitted: false,
            row_id: null,
            add: {
                TemplateDescription: '',
                templateuniqueid: '',
            },
            edit: {
                TemplateDescription: '',
                templateuniqueid: '',
            },
            foundUniqueIdErrorMsg: '',
            foundUniqueId: '',
        };

    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getAllTemplateDescriptions();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
        this.imagestorageimgpath = this.storage_path;
    },
    validations() {
        return {
            add: {
                TemplateDescription: { required: helpers.withMessage('Please select template description', required) },
                templateuniqueid: { required: helpers.withMessage('Please enter template unique Id', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), maxLength: maxLength(3) },
            },
            edit: {
                TemplateDescription: { required: helpers.withMessage('Please select template description', required) },
                templateuniqueid: { required: helpers.withMessage('Please enter template unique Id', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), maxLength: maxLength(3) },
            },

        };
    },
    methods: {
        onSubmit() {
            return;
        },

        getAllTemplateDescriptions(ev) {
            this.loading = true;
            this.ApiService.getAllTemplateDescriptions(ev).then((data) => {
                if (data.status == 200) {
                    this.tempDescList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.tempDescList = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAllTemplateDescriptions({ page_no: event.page });
        },

        isAlreadyPresentTemplateDescriptionId(event) {
            this.ApiService.isAlreadyPresentTemplateDescriptionId({ uniqueId: event.target.value, rowId: this.row_id }).then((data) => {
                if (data.status == 200) {
                    this.foundUniqueIdErrorMsg = '';
                    this.foundUniqueId = data.data;
                    if (data.count > 0)
                        this.foundUniqueIdErrorMsg = "This id is found already please enter different Id";
                    this.loading = false;
                } else {
                    this.foundUniqueIdErrorMsg = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },

        addTempDescDialogOpen() {
            this.foundUniqueIdErrorMsg = '';
            this.submitted = false;
            this.addTemplateDescStatus = true;
        },

        editTemplateDescDialogOpen(e) {
            this.foundUniqueIdErrorMsg = '';
            this.getAllTemplateDescriptions();
            this.editTemplateDescStatus = true;
            this.row_id = e.mbs1;
            this.edit.TemplateDescription = e.mbs2;
            this.edit.templateuniqueid = e.mbs3;
        },

        addTemplateDescriptions() {
            this.errormsg = '';
            this.submitted = true;
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append('mbs2', this.add.TemplateDescription);
            formData.append('mbs3', this.add.templateuniqueid);
            if (!this.v$.add.$error && this.foundUniqueIdErrorMsg == '') {
                this.showLoader = true;
                this.ApiService.createUpdateTemplateDescription(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.addTemplateDescStatus = false;
                        this.add.TemplateDescription = '';
                        this.add.templateuniqueid = '';
                        this.getAllTemplateDescriptions();
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                    } else {
                        this.addTemplateDescStatus = true;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        updateTemplateDescriptions() {
            this.submitted = true;
            this.v$.edit.$validate();
            var formData = new FormData();
            formData.append('mbs1', this.row_id);
            formData.append('mbs2', this.edit.TemplateDescription);
            formData.append('mbs3', this.edit.templateuniqueid);
            if (!this.v$.edit.$error && this.foundUniqueIdErrorMsg == '') {
                this.showLoader = true;
                this.ApiService.createUpdateTemplateDescription(formData).then((items) => {
                    if (items.status == 200) {
                        this.showLoader = false;
                        this.editTemplateDescStatus = false;
                        this.edit.TemplateDescription = '';
                        this.edit.templateuniqueid = '';
                        this.getAllTemplateDescriptions();
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    } else {
                        this.editTemplateDescStatus = true;
                        this.showLoader = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
